import React, { useState, useEffect, useRef } from 'react';

interface Props {
    lfButton: boolean;
    fileUrl: string;
    setLessonPosition: Function;
    lessonPosition: number;
    setLessonFinish: Function;
}

const VideoLayout: React.FC<Props> = ({ lfButton, fileUrl, setLessonPosition, lessonPosition, setLessonFinish }) => {
    const [totalSeconds, setTotalSeconds] = useState(100000);
    const [seconds, setSeconds] = useState(0);
    const videoRef = useRef<any>();
    console.log(fileUrl)
    useEffect(() => {
        setSeconds(lessonPosition)
        const video = videoRef.current;
        video.currentTime = lessonPosition;
    }, [lessonPosition])

    useEffect(() => {
        videoRef.current?.load();
    }, [fileUrl]);

    useEffect(() => {
        const interval = setInterval(() => {
            const video = videoRef.current;
            if (!video) return;
            setLessonPosition(Math.floor(video.currentTime));
            setTotalSeconds(video.duration)
            console.log(video.currentTime);
            console.log(`The video is ${video.duration} seconds long.`);
            console.log(totalSeconds, lessonPosition, Math.floor(video.currentTime))
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    const handleLoadedMetadata = () => {
        console.log(totalSeconds, lessonPosition)
        const video = videoRef.current;
        if (!video) return;
        video.currentTime = lessonPosition;
        console.log(video.currentTime);
        console.log(`The video is ${video.duration} seconds long.`);
    };


    return (
        <>
            <div>
                <video preload="none" ref={videoRef} height="100%" autoPlay controls onLoadedMetadata={handleLoadedMetadata} >
                    <source src={fileUrl} type="video/mp4" />
                </video>
                {
                    totalSeconds <= (lessonPosition + 20) && lfButton &&
                    (
                        <button
                        type='button'
                        style={{ width: "200px", position: "fixed", bottom: "0px", right: "200px" }}
                        disabled={lessonPosition < 1}
                        className='btn btn-sm btn-dark btn-active-light-dark  mt-3 mb-3'
                        onClick={() => setLessonFinish()}
                        >
                        {'Finish Lesson'}
                        </button>
                    )
                }
            </div>

        </>
    );
}

export default VideoLayout;