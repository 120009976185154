import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {KTIcon} from '../../../_metronic/helpers'
import React, {useEffect, useState} from 'react'
import {listGamingScore} from '../gaming/list/core/_requests'

const Board = () => {
  const [data, setData] = useState<any>()

  useEffect(() => {
    listGamingScore().then((res: any) => {
      console.log(res)
      setData(res.data)
    })
  }, [])
  return (
    <div className='card mb-7'>
      {/* begin::Header */}
      <div className='card-header align-items-center border-0 mt-3'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bolder text-dark fs-3'>Liderlik Panosu</span>
          <span className='text-gray-400 mt-2 fw-bold fs-6'></span>
        </h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-clean btn-sm btn-icon btn-icon-primary btn-active-light-primary me-n3'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          ></button>

          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-5'>
        {/*begin::Item*/}
        {data?.map((item: any) => {
          return (
            <div className='d-flex mb-7'>
              {/*begin::Symbol*/}
              <div className='symbol symbol-65px w-65px bg-light me-5'>
                <img
                  src={toAbsoluteUrl('/media/illustrations/unitedpalms-1/15.png')}
                  className='mw-100'
                  alt=''
                />
              </div>
              {/*end::Symbol*/}
              {/*begin::Section*/}
              <div className='d-flex align-items-center flex-wrap flex-grow-1 mt-n2 mt-lg-n1'>
                {/*begin::Title*/}
                <div className='d-flex flex-column flex-grow-1 my-lg-0 my-2 pe-3'>
                  <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                    {item?.name}
                  </a>
                  {/* <span className='text-gray-400 fw-bold fs-7 my-1'>Study highway types</span>
                  <span className='text-gray-400 fw-bold fs-7'>
                    By:
                    <a href='#' className='text-primary fw-bold'>
                      CoreAd
                    </a>
                  </span> */}
                </div>
                {/*end::Title*/}
                {/*begin::Info*/}
                <div className='text-end py-lg-0 py-2'>
                  <span className='text-gray-800 fw-boldest fs-2'>{item?.score}</span>
                  <span className='text-gray-400 fs-7 fw-bold d-block'>Puan</span>
                </div>
                {/*end::Info*/}
              </div>
              {/*end::Section*/}
            </div>
          )
        })}

        {/*end::Item*/}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {Board}
