import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {Model, QueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const PROP_URL = `${API_URL}/ApplicationUser`
const PROP_URL2 = `${API_URL}/Auth/register`
const PROP_URL3 = `${API_URL}/Auth/update-user`
const DELETE_USER_URL = `${API_URL}/Custom/deleteUser`
const GET_USER = `${API_URL}/Custom/listParticipant`

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
const getApplicationUser = (
  query: string,
  select?: string,
  modelstoinclude?: string
): Promise<QueryResponse> => {
  const qis = query.split('&')
  let newq: Array<string> = []
  qis.map((q) => {
    if (q.startsWith('filter') && q.slice(-1) != '=') {
      if (q.includes('_uuu')) {
        const aq = capitalizeFirstLetter(
          q.replace('_id', 'Id').replace('filter_', '').replace('=', '*')
        )
        //console.log(aq)
        newq.push(aq)
      } else if (q.includes('strt') && !q.includes(' ')) {
        const aq =
          '(Date)' +
          capitalizeFirstLetter(
            q.replace('strt', '').replace('filter_', '').replaceAll('-', '.').replace('=', '>')
          )
        //console.log(aq)
        newq.push(aq)
      } else if (q.includes('ndnd') && !q.includes(' ')) {
        const aq =
          '(Date)' +
          capitalizeFirstLetter(
            q.replace('ndnd', '').replace('filter_', '').replaceAll('-', '.').replace('=', '<')
          )
        //console.log(aq)
        newq.push(aq)
      } else if (q.includes('string')) {
        const aq = capitalizeFirstLetter(
          q.replace('string', '').replace('filter_', '').replace('=', '_')
        )
        //console.log(aq)
        newq.push(aq)
      } else if (q.includes('stNMB')) {
        const aq = capitalizeFirstLetter(
          q.replace('stNMB', '').replace('filter_', '').replace('=', '>')
        )
        //console.log(aq)
        newq.push(aq)
      } else if (q.includes('enNMB')) {
        const aq = capitalizeFirstLetter(
          q.replace('enNMB', '').replace('filter_', '').replace('=', '<')
        )
        //console.log(aq)
        newq.push(aq)
      } else if (q.includes('BL')) {
        const aq = capitalizeFirstLetter(
          q.replace('BL', '').replace('filter_', '').replace('=', '*')
        )
        //console.log(aq)
        newq.push(aq)
      }
    }
  })
  const newqstring = newq.filter((f) => f).join('|')

  let mti = ''
  if (modelstoinclude != undefined && modelstoinclude != '') {
    mti = '&modelstoinclude=' + modelstoinclude
  }
  let sti = ''
  if (select != undefined && select != '') {
    sti = '&select=' + select
  }

  return axios
    .get(`${PROP_URL}/getAll/${newqstring}?${query}${mti}${sti}`)
    .then((d: AxiosResponse<QueryResponse>) => d.data)
}

const listApplicationUser = async (select?: string, modelstoinclude?: string): Promise<any> => {
  let mti = ''
  if (modelstoinclude != undefined) {
    mti = '&modelstoinclude=' + modelstoinclude
  }
  return await axios
    .get(`${PROP_URL}/getAll?page=1&items_per_page=99999${mti}&select=${select}`)
    .then((res: AxiosResponse) => {
      return res.data
    })
}

const listCustomApplicationUser = async (): Promise<any> => {
  return await axios.get(`${GET_USER}`).then((res: AxiosResponse) => {
    return res.data
  })
}

const getApplicationUserById = (id: any): Promise<Model | undefined> => {
  return axios
    .get(`${PROP_URL}/${id}?modelstoinclude=Organization`)
    .then((response: AxiosResponse<Response<Model>>) => response.data)
    .then((response: Response<Model>) => response as any)
}

const getApplicationUserById2 = (id: any): Promise<Model | undefined> => {
  return axios
    .get(`${PROP_URL}/getAll/Id*${id}?modelstoinclude=Organization`)
    .then((response: AxiosResponse<Response<Model>>) => response.data)
    .then((response: Response<Model>) => response as any)
}

const getApplicationUserByOrnagizationId = (id: any): Promise<Model | undefined> => {
  return axios
    .get(`${PROP_URL}/getAll/OrganizationId*${id}?modelstoinclude=Organization`)
    .then((response: AxiosResponse<Response<Model>>) => response.data)
    .then((response: Response<Model>) => response as any)
}

const createApplicationUser = (prop: Model): Promise<Model | undefined> => {
  return axios
    .put(PROP_URL2, prop)
    .then((response: AxiosResponse<Response<Model>>) => response.data)
    .then((response: Response<Model>) => response.data)
}

const updateApplicationUser = (prop: Model): Promise<Model | undefined> => {
  return axios
    .post(`${PROP_URL3}`, prop)
    .then((response: AxiosResponse<Response<Model>>) => response.data)
    .then((response: Response<Model>) => response.data)
}

const deleteApplicationUser = (propId: ID): Promise<void> => {
  return axios.delete(`${PROP_URL}/${propId}`).then(() => {})
}

const deleteSelectedApplicationUser = (propIds: Array<ID>): Promise<void> => {
  const requests = propIds.map((id) => axios.delete(`${PROP_URL}/${id}`))
  return axios.all(requests).then(() => {})
}
const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${DELETE_USER_URL}/${userId}?forcedelete=true`).then((res) => {})
}

export {
  getApplicationUser,
  deleteApplicationUser,
  deleteSelectedApplicationUser,
  getApplicationUserById,
  createApplicationUser,
  updateApplicationUser,
  listApplicationUser,
  deleteUser,
  getApplicationUserById2,
  listCustomApplicationUser,
  getApplicationUserByOrnagizationId,
}
