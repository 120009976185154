import React, {Component, useEffect, useState} from 'react'
import ReactAudioPlayer from 'react-audio-player'
import {listStory} from '../story/list/core/_requests'

const MediaPlayer = () => {
  const [file, setFile] = useState<any>()
  useEffect(() => {
    listStory('Id,Name,File').then((res: any) => {
      console.log(res)
      setFile(res.data)
    })
  }, [])

  return (
    <>
      {file?.length > 0 ? (
        <ReactAudioPlayer src={`https://api.tonyuk.com/${file[0].file}`} controls />
      ) : null}
    </>
  )
}

export {MediaPlayer}
