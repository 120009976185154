import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {MasterLayoutOrange} from '../../_metronic/layout/MasterLayoutOrange'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {SurveyReportPage} from '../pages/survey-report/Survey'
import {MySurveyPage} from '../pages/my-survey/Survey'
import {ProfileInfo} from '../pages/user-success-report/ProfileInfo/ProfileInfo'
import {Courses} from '../pages/user-success-report/Courses/Courses'
import {Lessons} from '../pages/user-success-report/Lesson/Lesson'
import {Quiz} from '../pages/user-success-report/General-Quiz/Quiz'
import {Quiz2} from '../pages/user-success-report/Course-Quiz/Quiz'
import {ViewAnswers} from '../pages/user-success-report/ViewAnswers/ViewAnswers'
import {HRReport} from '../pages/user-success-report/HR-Report/HR-Report-Page'
import {GeneralHRReport} from '../pages/report-Hr/hr-report/hr-report'
import {Survey} from '../pages/user-success-report/Survey/Survey'
import {QuizReport} from '../pages/report/quiz-report/quiz'
import {TopicReport} from '../pages/report/topic-report/topic'
import {TotalQuizReport} from '../pages/report/quiz-report/TotalQuiz'
import {CourseReport} from '../pages/report/course-report/course'
import {LessonViewer} from '../pages/my-topic/LessonViewer'
import {Topic} from '../pages/user-success-report/Topic/Topic'
import {CourseSuccessReport} from '../pages/report/course-success/courseSuccess'
import Notification from '../pages/newNotification/Notofications'

const PrivateRoutes = (menus: Array<string>) => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  //const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const UsersPage = lazy(() => import('../pages/applicationuser/Page'))
  const ModelListPage = lazy(() => import('../pages/modellist/Page'))
  const MethodListPage = lazy(() => import('../pages/methodlist/Page'))
  const RoleMethodListPage = lazy(() => import('../pages/rolemethodlist/Page'))
  const RoleModelListPage = lazy(() => import('../pages/rolemodellist/Page'))
  const MenuListPage = lazy(() => import('../pages/menulist/Page'))
  const MenuRolePage = lazy(() => import('../pages/menurole/Page'))
  const UserActionPage = lazy(() => import('../pages/useraction/Page'))
  const CulturePage = lazy(() => import('../pages/culture/Page'))
  const TranslationPage = lazy(() => import('../pages/translation/Page'))
  const ApplicationUserPage = lazy(() => import('../pages/applicationuser/Page'))
  const ApplicationRolePage = lazy(() => import('../pages/applicationrole/Page'))
  const ApplicationUserRolePage = lazy(() => import('../pages/applicationuserrole/Page'))
  const ApplicationUserClaimPage = lazy(() => import('../pages/applicationuserclaim/Page'))
  const ApplicationRoleClaimPage = lazy(() => import('../pages/applicationroleclaim/Page'))
  const TenantPage = lazy(() => import('../pages/tenant/Page'))
  const CodePage = lazy(() => import('../pages/code/Page'))
  const OrganizationPage = lazy(() => import('../pages/organization/Page'))
  const TicketPage = lazy(() => import('../pages/ticket/Page'))
  const TicketReplyPage = lazy(() => import('../pages/ticketreply/Page'))
  const AppModulePage = lazy(() => import('../pages/appmodule/Page'))
  const TenantAppModulePage = lazy(() => import('../pages/tenantappmodule/Page'))
  const AnnouncementPage = lazy(() => import('../pages/announcement/Page'))
  const CoursePage = lazy(() => import('../pages/course/Page'))
  const CourseTermPage = lazy(() => import('../pages/courseterm/Page'))
  const TermUserPage = lazy(() => import('../pages/termuser/Page'))
  const QuizStatusPage = lazy(() => import('../pages/quizstatus/Page'))
  const TopicPage = lazy(() => import('../pages/topic/Page'))
  const LessonPage = lazy(() => import('../pages/lesson/Page'))
  const LessonUserPage = lazy(() => import('../pages/lessonuser/Page'))
  const LessonUserActionPage = lazy(() => import('../pages/lessonuseraction/Page'))
  const LibraryCategoryPage = lazy(() => import('../pages/librarycategory/Page'))
  const SettingsPage = lazy(() => import('../pages/settings/Page'))
  const LibraryPage = lazy(() => import('../pages/library/Page'))
  const LibraryTenantPage = lazy(() => import('../pages/librarytenant/Page'))
  const AnswerTemplatePage = lazy(() => import('../pages/answertemplate/Page'))
  const AnswerTemplateOptionPage = lazy(() => import('../pages/answertemplateoption/Page'))
  const QuestionPage = lazy(() => import('../pages/question/Page'))
  const QuizPage = lazy(() => import('../pages/quiz/Page'))
  const QuizQuestionsPage = lazy(() => import('../pages/quizquestions/Page'))
  const AnswerPage = lazy(() => import('../pages/answer/Page'))
  const CertificatePage = lazy(() => import('../pages/certificate/Page'))
  const SurveyPage = lazy(() => import('../pages/survey/Page'))
  const SurveyGroupPage = lazy(() => import('../pages/surveygroup/Page'))
  const SurveyQuestionPage = lazy(() => import('../pages/surveyquestion/Page'))
  const SurveyAnswerPage = lazy(() => import('../pages/surveyanswer/Page'))
  const SurveyCommentPage = lazy(() => import('../pages/surveycomment/Page'))
  const ScormPackagePage = lazy(() => import('../pages/scormpackage/Page'))
  const ScormModulePage = lazy(() => import('../pages/scormmodule/Page'))
  const VirtualClassPage = lazy(() => import('../pages/virtualclass/Page'))
  const VirtualClassUserPage = lazy(() => import('../pages/virtualclassuser/Page'))
  const MyCoursePage = lazy(() => import('../pages/my-course/Page'))
  const MyTopicPage = lazy(() => import('../pages/my-topic/Page'))
  const MyQuizPage = lazy(() => import('../pages/my-quiz/Page'))
  const Code35CatalogPage = lazy(() => import('../pages/code35-catalog/Page'))
  const CatalogPage = lazy(() => import('../pages/catalog/Page'))
  const MyCertificatePage = lazy(() => import('../pages/my-certificate/Page'))
  const MyVirtualClassPage = lazy(() => import('../pages/my-virtualclass/Page'))
  const InvontoryCategoryPage = lazy(() => import('../pages/inventorycategory/Page'))
  const HrDimensionPage = lazy(() => import('../pages/hrdimension/Page'))
  const InventoryTestPage = lazy(() => import('../pages/inventorytest/Page'))
  const JourneyPage = lazy(() => import('../pages/journey/Page'))
  const TenantJourneyPage = lazy(() => import('../pages/tenant-journey/Page'))
  const StoryPage = lazy(() => import('../pages/story/Page'))
  const GainPage = lazy(() => import('../pages/gain/Page'))
  const HolidayPage = lazy(() => import('../pages/holiday/Page'))
  const CommentPage = lazy(() => import('../pages/comment/Page'))
  const TenantGamingPage = lazy(() => import('../pages/tenantgaming/Page'))

  return (
    <Routes>
      <Route element={<MasterLayout {...(menus || [])} />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path='/survey-report' element={<SurveyReportPage />} />
        <Route path='/my-survey/:QuizId' element={<MySurveyPage />} />
        <Route path='/user-report-profile' element={<ProfileInfo />} />
        <Route path='/user-report-course' element={<Courses />} />
        <Route path='/user-report-lesson' element={<Lessons />} />
        <Route path='/user-report-quiz' element={<Quiz />} />
        <Route path='/user-report-course-quiz' element={<Quiz2 />} />
        <Route path='/user-report-survey' element={<Survey />} />
        <Route path='/user-report-viewAnswers' element={<ViewAnswers />} />
        <Route path='/reports' element={<QuizReport />} />
        <Route path='/topic-reports' element={<TopicReport />} />
        <Route path='/quiz-reports' element={<TotalQuizReport />} />
        <Route path='/course-reports' element={<CourseReport />} />
        <Route path='/user-topic-reports' element={<Topic />} />
        <Route path='/user-hr-report' element={<HRReport />} />
        <Route path='/hr-report' element={<GeneralHRReport />} />
        <Route path='/view-lesson' element={<LessonViewer />} />
        <Route path='/course-success' element={<CourseSuccessReport />} />
        <Route path='/notification' element={<Notification />} />

        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='tenantgaming/*'
          element={
            <SuspensedView>
              <TenantGamingPage />
            </SuspensedView>
          }
        />
        <Route
          path='story/*'
          element={
            <SuspensedView>
              <StoryPage />
            </SuspensedView>
          }
        />
        <Route
          path='holiday/*'
          element={
            <SuspensedView>
              <HolidayPage />
            </SuspensedView>
          }
        />
        <Route
          path='comment/*'
          element={
            <SuspensedView>
              <CommentPage />
            </SuspensedView>
          }
        />
        <Route
          path='mygain/*'
          element={
            <SuspensedView>
              <GainPage />
            </SuspensedView>
          }
        />
        <Route
          path='catalog/*'
          element={
            <SuspensedView>
              <CatalogPage />
            </SuspensedView>
          }
        />
        <Route
          path='tenantjourney/*'
          element={
            <SuspensedView>
              <TenantJourneyPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='my-course/*'
          element={
            <SuspensedView>
              <MyCoursePage />
            </SuspensedView>
          }
        />
        <Route
          path='my-virtualclass/*'
          element={
            <SuspensedView>
              <MyVirtualClassPage />
            </SuspensedView>
          }
        />
        <Route
          path='my-certificate/*'
          element={
            <SuspensedView>
              <MyCertificatePage />
            </SuspensedView>
          }
        />
        <Route
          path='code35-catalog/*'
          element={
            <SuspensedView>
              <Code35CatalogPage />
            </SuspensedView>
          }
        />
        <Route
          path='my-quiz/*'
          element={
            <SuspensedView>
              <MyQuizPage />
            </SuspensedView>
          }
        />
        <Route
          path='my-topic/*'
          element={
            <SuspensedView>
              <MyTopicPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='modellist/*'
          element={
            <SuspensedView>
              <ModelListPage />
            </SuspensedView>
          }
        />
        <Route
          path='methodlist/*'
          element={
            <SuspensedView>
              <MethodListPage />
            </SuspensedView>
          }
        />
        <Route
          path='rolemethodlist/*'
          element={
            <SuspensedView>
              <RoleMethodListPage />
            </SuspensedView>
          }
        />
        <Route
          path='rolemodellist/*'
          element={
            <SuspensedView>
              <RoleModelListPage />
            </SuspensedView>
          }
        />
        <Route
          path='menulist/*'
          element={
            <SuspensedView>
              <MenuListPage />
            </SuspensedView>
          }
        />
        <Route
          path='menurole/*'
          element={
            <SuspensedView>
              <MenuRolePage />
            </SuspensedView>
          }
        />
        <Route
          path='user-action/*'
          element={
            <SuspensedView>
              <UserActionPage />
            </SuspensedView>
          }
        />
        <Route
          path='culture/*'
          element={
            <SuspensedView>
              <CulturePage />
            </SuspensedView>
          }
        />
        <Route
          path='translation/*'
          element={
            <SuspensedView>
              <TranslationPage />
            </SuspensedView>
          }
        />
        <Route
          path='user-management/*'
          element={
            <SuspensedView>
              <ApplicationUserPage />
            </SuspensedView>
          }
        />
        <Route
          path='applicationrole/*'
          element={
            <SuspensedView>
              <ApplicationRolePage />
            </SuspensedView>
          }
        />
        <Route
          path='applicationuserrole/*'
          element={
            <SuspensedView>
              <ApplicationUserRolePage />
            </SuspensedView>
          }
        />
        <Route
          path='applicationuserclaim/*'
          element={
            <SuspensedView>
              <ApplicationUserClaimPage />
            </SuspensedView>
          }
        />
        <Route
          path='applicationroleclaim/*'
          element={
            <SuspensedView>
              <ApplicationRoleClaimPage />
            </SuspensedView>
          }
        />
        <Route
          path='tenant/*'
          element={
            <SuspensedView>
              <TenantPage />
            </SuspensedView>
          }
        />
        <Route
          path='code/*'
          element={
            <SuspensedView>
              <CodePage />
            </SuspensedView>
          }
        />
        <Route
          path='organization/*'
          element={
            <SuspensedView>
              <OrganizationPage />
            </SuspensedView>
          }
        />
        <Route
          path='ticket/*'
          element={
            <SuspensedView>
              <TicketPage />
            </SuspensedView>
          }
        />
        <Route
          path='ticketreply/*'
          element={
            <SuspensedView>
              <TicketReplyPage />
            </SuspensedView>
          }
        />
        <Route
          path='appmodule/*'
          element={
            <SuspensedView>
              <AppModulePage />
            </SuspensedView>
          }
        />
        <Route
          path='tenantappmodule/*'
          element={
            <SuspensedView>
              <TenantAppModulePage />
            </SuspensedView>
          }
        />
        <Route
          path='announcement/*'
          element={
            <SuspensedView>
              <AnnouncementPage />
            </SuspensedView>
          }
        />
        <Route
          path='course/*'
          element={
            <SuspensedView>
              <CoursePage />
            </SuspensedView>
          }
        />
        <Route
          path='courseterm/*'
          element={
            <SuspensedView>
              <CourseTermPage />
            </SuspensedView>
          }
        />
        <Route
          path='termuser/*'
          element={
            <SuspensedView>
              <TermUserPage />
            </SuspensedView>
          }
        />
        <Route
          path='quizstatus/*'
          element={
            <SuspensedView>
              <QuizStatusPage />
            </SuspensedView>
          }
        />
        <Route
          path='topic/*'
          element={
            <SuspensedView>
              <TopicPage />
            </SuspensedView>
          }
        />
        <Route
          path='lesson/*'
          element={
            <SuspensedView>
              <LessonPage />
            </SuspensedView>
          }
        />
        <Route
          path='lessonuser/*'
          element={
            <SuspensedView>
              <LessonUserPage />
            </SuspensedView>
          }
        />
        <Route
          path='lessonuseraction/*'
          element={
            <SuspensedView>
              <LessonUserActionPage />
            </SuspensedView>
          }
        />
        <Route
          path='librarycategory/*'
          element={
            <SuspensedView>
              <LibraryCategoryPage />
            </SuspensedView>
          }
        />
        <Route
          path='settings/*'
          element={
            <SuspensedView>
              <SettingsPage />
            </SuspensedView>
          }
        />
        <Route
          path='library/*'
          element={
            <SuspensedView>
              <LibraryPage />
            </SuspensedView>
          }
        />
        <Route
          path='librarytenant/*'
          element={
            <SuspensedView>
              <LibraryTenantPage />
            </SuspensedView>
          }
        />
        <Route
          path='answertemplate/*'
          element={
            <SuspensedView>
              <AnswerTemplatePage />
            </SuspensedView>
          }
        />
        <Route
          path='answertemplateoption/*'
          element={
            <SuspensedView>
              <AnswerTemplateOptionPage />
            </SuspensedView>
          }
        />
        <Route
          path='question/*'
          element={
            <SuspensedView>
              <QuestionPage />
            </SuspensedView>
          }
        />
        <Route
          path='quiz/*'
          element={
            <SuspensedView>
              <QuizPage />
            </SuspensedView>
          }
        />
        <Route
          path='quizquestions/*'
          element={
            <SuspensedView>
              <QuizQuestionsPage />
            </SuspensedView>
          }
        />
        <Route
          path='answer/*'
          element={
            <SuspensedView>
              <AnswerPage />
            </SuspensedView>
          }
        />
        <Route
          path='certificate/*'
          element={
            <SuspensedView>
              <CertificatePage />
            </SuspensedView>
          }
        />
        <Route
          path='survey/*'
          element={
            <SuspensedView>
              <SurveyPage />
            </SuspensedView>
          }
        />
        <Route
          path='surveygroup/*'
          element={
            <SuspensedView>
              <SurveyGroupPage />
            </SuspensedView>
          }
        />
        <Route
          path='surveyquestion/*'
          element={
            <SuspensedView>
              <SurveyQuestionPage />
            </SuspensedView>
          }
        />
        <Route
          path='surveyanswer/*'
          element={
            <SuspensedView>
              <SurveyAnswerPage />
            </SuspensedView>
          }
        />
        <Route
          path='surveycomment/*'
          element={
            <SuspensedView>
              <SurveyCommentPage />
            </SuspensedView>
          }
        />
        <Route
          path='scormpackage/*'
          element={
            <SuspensedView>
              <ScormPackagePage />
            </SuspensedView>
          }
        />
        <Route
          path='scormmodule/*'
          element={
            <SuspensedView>
              <ScormModulePage />
            </SuspensedView>
          }
        />
        <Route
          path='virtualclass/*'
          element={
            <SuspensedView>
              <VirtualClassPage />
            </SuspensedView>
          }
        />
        <Route
          path='virtualclassuser/*'
          element={
            <SuspensedView>
              <VirtualClassUserPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>

      <Route element={<MasterLayoutOrange {...(menus || [])} />}>
        <Route
          path='inventorycategory/*'
          element={
            <SuspensedView>
              <InvontoryCategoryPage />
            </SuspensedView>
          }
        />

        <Route
          path='hrdimension/*'
          element={
            <SuspensedView>
              <HrDimensionPage />
            </SuspensedView>
          }
        />
        <Route
          path='inventories/*'
          element={
            <SuspensedView>
              <InventoryTestPage />
            </SuspensedView>
          }
        />

        <Route
          path='journeys/*'
          element={
            <SuspensedView>
              <JourneyPage />
            </SuspensedView>
          }
        />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
